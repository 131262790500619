import React from 'react';
import { Modal, ModalBody, useDisclosure, ModalOverlay, ModalContent, Tooltip, Avatar } from "@chakra-ui/react";

function Person(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
                {props.object}
            </ModalBody>
          </ModalContent>
        </Modal>

        <Tooltip label={props.label}>
            <Avatar cursor="pointer" onClick={onOpen} size="2x1" name={props.name} src={props.image} />
        </Tooltip>
      </>
    )
  }

export default Person;