import React from 'react';
import {
  ChakraProvider,
  Box,
  Image,
  Text,
  Grid,
  theme,
  Wrap,
  WrapItem,
  Avatar,
  Tooltip,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  Center
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';

import Person from './components/person.js';
import Me from './assets/idiot_pics/me.png';
import Puff from './assets/idiot_pics/puff.png';
import Grant from './assets/idiot_pics/grant.png';
import Andy from './assets/idiot_pics/andy.png';
import Nick from './assets/idiot_pics/cheesy.png';
import Jesse from './assets/idiot_pics/jesse.png';
import Will from './assets/idiot_pics/will.png';
import Tommy from './assets/idiot_pics/tommy.png';
import Bo from './assets/idiot_pics/bo.png';
import Jorge from './assets/idiot_pics/jorge.png';
import Hayden from './assets/idiot_pics/hayden.png';
import Dunky from './assets/idiot_pics/dunky.png';

import construction from './assets/construct.gif'
import discord from './assets/discord.png';
import codenames from './assets/codenames.png';
import what from './assets/john.webp'
import skribbl from './assets/skribbl.gif'
import secretHitler from './assets/secret-hitler.png';
import drawBattle from './assets/drawBattle.png';
import garticPhone from './assets/gartic.png';

import jesseClick from './assets/jesseClick.png'
import willClick from './assets/willClick.png'
import andyClick from './assets/andyClick.png'
import dunkyClick from './assets/dunkyClick.png'
import boClick from './assets/boClick.png'
import haydenClick from './assets/haydenClick.png'
import tommyClick from './assets/tommyClick.png'

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  theme.config.initialColorMode = "dark";
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <Image position="absolute" src={construction}></Image>
          <ColorModeSwitcher justifySelf="flex-end" />

          <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <Image src={what}></Image>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Text fontSize="7xl"><b>Dumb-Idiots Have a <Tooltip label="DumbIdiots.com was taken."><u onClick={onOpen}>Website</u></Tooltip></b></Text>

          <Wrap spacing="125px" justify="center" marginBottom="50px" align="center">
          <WrapItem>
            <Box>
              <LinkBox as="image" maxW="xs">
                <LinkOverlay href="https://www.horsepaste.com/DumbIdiots">
                <Center >
                  <Image src={codenames} maxHeight="200"></Image>
                </Center>
                </LinkOverlay>
              </LinkBox>
              <LinkBox as="image" maxW="xs" >
                <LinkOverlay href="https://secret-hitler.com/">
                  <Image src={secretHitler} maxHeight="200"></Image>
                </LinkOverlay>
              </LinkBox>
            </Box>

          </WrapItem>
          <WrapItem>
            <LinkBox as="image" maxW="xs" rounded="md">
              <LinkOverlay href="https://discord.gg/2TzpAbwXMC">
                <Image src={discord}></Image>
              </LinkOverlay>
            </LinkBox>
          </WrapItem>

          <WrapItem>
            <Box maxW="xs">
              <LinkBox as="image" maxW="xs" rounded="md" padding="0">
                <LinkOverlay href="https://skribbl.io/">
                  <Center>
                    <Image src={skribbl}></Image>
                  </Center>
                </LinkOverlay>
              </LinkBox>

              <LinkBox as="image" maxW="xs" rounded="md">
                <LinkOverlay href="https://drawbattle.io/">
                  <Center>
                    <Image src={drawBattle}></Image>
                  </Center>
                </LinkOverlay>
              </LinkBox>

              <LinkBox as="image" maxW="xs" rounded="md">
                <LinkOverlay href="https://garticphone.com/">
                  <Center>
                    <Image src={garticPhone}></Image>
                  </Center>
                </LinkOverlay>
              </LinkBox>
            </Box>

          </WrapItem>



          </Wrap>
          <Wrap spacing="30px" margin="5px" justify="center" verticalAlign="middle">
            <WrapItem>
            <LinkBox>
                  <LinkOverlay href="https://www.wikihow.com/Stay-Up-to-Watch-a-Film" isExternal>
                    <Tooltip label="Hey I'm Camel, I made this page">
                      <Avatar size="2x1" name="Camel" src={Me} />
                    </Tooltip>
                  </LinkOverlay>
              </LinkBox>

            </WrapItem>

            <WrapItem>
              <LinkBox>
                  <LinkOverlay href="https://sound.stackexchange.com/questions/31829/i-want-to-know-how-to-shout-into-my-mic-without-distortion" isExternal>
                    <Tooltip label="This is Puffy; they call him Tommy">
                      <Avatar size="x1" name="Puffy" src={Puff} />
                    </Tooltip>
                  </LinkOverlay>
              </LinkBox>

            </WrapItem>

            <WrapItem>
              <Person name="Jorge" label="Our resident Jorge" image={Jorge} object={
                <iframe title="aaa" width="400" height="250" src="https://yewtu.be/embed/JmzUDj6aFzU"
                frameborder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>}>
              </Person>
            </WrapItem>

            <WrapItem>
              <Person name="TheBoCode" label="This is Bo; we like Bo" image={Bo} object={<Image src={boClick}></Image>}></Person>
            </WrapItem>

            <WrapItem>
              <LinkBox>
                <LinkOverlay href="https://www.reddit.com/r/weeabootales/comments/4g921c/how_i_lost_my_boyfriend_to_anime/" isExternal>
                  <Tooltip label="Cheesyshark doo, doo, doo, doo, doo, doo">
                    <Avatar size="x1" name="Cheesyshark" src={Nick} />
                  </Tooltip>
                </LinkOverlay>
              </LinkBox>
            </WrapItem>

            <WrapItem>
              <Person name="MOOK" label="This is Andy" image={Andy} object={<Image src={andyClick}></Image>}></Person>
            </WrapItem>

            <WrapItem>
              <Person name="Cwasont" label="This is Tommy, he's from Texas" image={Jesse} object={
                <Box>
                  <Image src={jesseClick}></Image>
                  <iframe title="aasa" width="400" height="250" src="https://www.youtube.com/embed/YWcrfp_dXKM?controls=0"
                    frameborder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </Box>}>
              </Person>

            </WrapItem>

            <WrapItem>
              <Person name="Smile" label="This is Smile, he has shiny teeth" image={Will} object={<Image src={willClick}></Image>}></Person>
            </WrapItem>

            <WrapItem>
              <Person name="Hayden Berry" label="This is envioushcb" image={Hayden} object={<Image src={haydenClick}></Image>}></Person>
            </WrapItem>

            <WrapItem>
              <Person name="Kavalier" label="This is Tommy, he lives in New York." image={Tommy} object={<Image src={tommyClick}></Image>}></Person>
            </WrapItem>

            <WrapItem>
              <LinkBox>
                <LinkOverlay href="https://www.dummies.com/games/chess/chess-for-dummies-cheat-sheet/" isExternal>
                  <Tooltip label="This is Garnt. He has the sauce. Ask him.">
                    <Avatar size="x1" name="Garnt" src={Grant} />
                  </Tooltip>
                </LinkOverlay>
              </LinkBox>
            </WrapItem>

            <WrapItem>
              <Person name="Dunky" label="Это наш товарищ осел" image={Dunky} object={<Image src={dunkyClick}></Image>}></Person>
            </WrapItem>

          </Wrap>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
